import React, { ReactNode } from 'react';
import { TableTree } from 'front-components/src/components/icons';
import { MenuItemIcon } from '../menuitem-icon';
import PageKey from '@myneva-portals/models/src/models/page-key';

class SettingsOrganizationMenuIcon implements MenuItemIcon {
  key: PageKey = PageKey.ORGANIZATION_SETTINGS;
  icon: ReactNode = (<TableTree />);
}

export const settingsOrganizationMenuIcon = new SettingsOrganizationMenuIcon();
