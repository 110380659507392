import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { getThemeMode } from 'front-components/src/tools/theme-extensions';

export const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      menuIcon: {
        color: getThemeMode(theme).icon.icon02,
      },
      menuAdditionalIcon: {
        color: getThemeMode(theme).icon.icon01,
      },
    }),
  { name: 'PortalHeader' }
);
