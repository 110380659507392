import React, { ReactNode } from 'react';
import { MenuItemIcon } from '../menuitem-icon';
import PageKey from '@myneva-portals/models/src/models/page-key';
import { UserCog } from 'front-components/src/components/icons';

class MySettingsMenuIcon implements MenuItemIcon {
  key: PageKey = PageKey.USER_SETTINGS_OVERVIEW;
  icon: ReactNode = (<UserCog />);
}

export const mySettingsMenuIcon = new MySettingsMenuIcon();
