import React, { ReactNode } from 'react';
import { MenuItemIcon } from '../menuitem-icon';
import PageKey from '@myneva-portals/models/src/models/page-key';
import { Suitcase } from 'front-components/src/components/icons';

class CustomerMenuIcon implements MenuItemIcon {
  key: PageKey = PageKey.CUSTOMERS;
  icon: ReactNode = (<Suitcase />);
}

export const customerMenuIcon = new CustomerMenuIcon();
