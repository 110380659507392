import React, { ReactNode } from 'react';
import { MenuItemIcon } from '../menuitem-icon';
import PageKey from '@myneva-portals/models/src/models/page-key';
import { Gears } from 'front-components/src/components/icons';

class GeneralSettingsMenuIcon implements MenuItemIcon {
  key: PageKey = PageKey.GENERAL_SETTINGS;
  icon: ReactNode = (<Gears />);
}

export const generalSettingsMenuIcon = new GeneralSettingsMenuIcon();
