import React from 'react';
import {
  MenuLayout,
  MenuItem,
  MenuHeader,
  ShrinkMenuButton,
  ButtonMenuItem,
  MenuItemProps,
  RawMenuItem,
} from 'front-components/src/components';
import { Button } from '@material-ui/core';
import { Link, navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import {
  navigationSelector,
  updateMenuStructure,
} from '../../state/reducers/navigation';
import PageKey from '@myneva-portals/models/src/models/page-key';
import { toggleExpandedSections } from '../../state/reducers/navigation/thunks';
import MenuSectionItem from '@myneva-portals/models/src/models/navigation/menu-section-item';
import { iconUtils } from '@myneva-portals/utils/src/utils/icons-utils';
import { MenuSection } from '@myneva-portals/models/src/models/navigation/menu-section';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { MenuType } from '@myneva-portals/models/src/models/navigation/menu-type';
import { getMenuStructure } from '../../navigation/current-menu';
import { ArrowLeftSolid } from 'front-components/src/components/icons';
import { useStyles } from './asside-menu.styles';
import { AdminMenuProps } from './asside-menu.props';

export const AdminMenu: React.FC<AdminMenuProps> = (props: AdminMenuProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateMenuStructure(
        props.type === undefined ? MenuType.MAIN_MENU : props.type
      )
    );
  }, [props.type]);

  const classes = useStyles();
  const { t } = useTranslation();

  const { selectedMenuItem, expandedSections, menuType, lastSelectedMenuItem } =
    useSelector(navigationSelector);

  const getMenuItemName = (key: PageKey): string => {
    return t(key);
  };

  const createLinkButton = (
    link: PageKey,
    name: string,
    icon: React.ReactNode,
    selectedKey: PageKey,
    level?: number,
    cyAttributeName?: string
  ): React.ReactElement<MenuItemProps> => {
    return (
      <Link className={classes.appLink} to={link}>
        <ButtonMenuItem
          name={t(name)}
          icon={icon}
          selected={selectedKey === link}
          level={level}
          cyName={`left-bar-${cyAttributeName}-button`}
        />
      </Link>
    );
  };

  const renderSectionItems = (
    items: MenuSectionItem[],
    level = 1
  ): React.ReactNode => {
    return (
      <>
        {items.map((item: MenuSectionItem) => {
          const children =
            item.items && renderSectionItems(item.items, level + 1);

          const toggleExpandedSectionsState = (
            expanded: boolean,
            key: PageKey
          ) => {
            dispatch(toggleExpandedSections(key));
          };

          const icon: React.ReactNode = iconUtils.getIconForMenuItem(item.key);
          const customButton: React.ReactElement<MenuItemProps> =
            createLinkButton(
              item.key,
              getMenuItemName(item.key),
              icon,
              selectedMenuItem,
              item.level ? item.level : level,
              item.name
            );
          const onChange = item.items
            ? (expanded: boolean) => {
                toggleExpandedSectionsState(expanded, item.key);
              }
            : undefined;

          return (
            <MenuItem
              key={item.key}
              name={getMenuItemName(item.key)}
              icon={icon}
              customButton={customButton}
              onChange={onChange}
              defaultExpanded={
                (expandedSections && expandedSections.includes(item.key)) ||
                false
              }
              cyName={`left-bar-${item.name}-expand-button`}
            >
              {children}
            </MenuItem>
          );
        })}
      </>
    );
  };

  const backButton = (
    <div className={classes.backButtonContainer}>
      <Button
        onClick={() => {
          navigate(lastSelectedMenuItem ?? PageKey.HOME);
        }}
      >
        <RawMenuItem
          name={t('BACK')}
          icon={<ArrowLeftSolid />}
          userClassName={classes.backButtonColors}
        />
      </Button>
    </div>
  );

  const menu =
    expandedSections === undefined ? null : (
      <div className={classes.menuContainer} data-cy="side-menu">
        {props.type === MenuType.USER_SETTINGS_MENU && backButton}
        {getMenuStructure(menuType).map(
          (section: MenuSection, index: number) => {
            return (
              <div key={`menu-section-${index}`}>
                {section.header && <MenuHeader header={section.header} />}
                {renderSectionItems(section.items)}
              </div>
            );
          }
        )}
      </div>
    );

  const footer = (
    <div className={classes.footerContainer}>
      <div className={classes.shrinkButtonContainer}>
        <ShrinkMenuButton />
      </div>
      <div className={classes.versionLabelContainer}>
        <label className={classes.versionLabel}>Version 98.7.20</label>
      </div>
    </div>
  );

  return (
    <div className={classes.adminMenuWrapper}>
      <MenuLayout menu={menu} footer={footer} />
    </div>
  );
};
