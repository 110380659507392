import React, { ReactNode } from 'react';

import { Users } from 'front-components/src/components/icons';
import { MenuItemIcon } from '../menuitem-icon';
import PageKey from '@myneva-portals/models/src/models/page-key';

class UsersMenuIcon implements MenuItemIcon {
  key: PageKey = PageKey.USERS;
  icon: ReactNode = (<Users />);
}

export const usersMenuIcon = new UsersMenuIcon();
