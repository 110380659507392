import React, { ReactNode } from 'react';

import { UserGroup } from 'front-components/src/components/icons';
import { MenuItemIcon } from '../menuitem-icon';
import PageKey from '@myneva-portals/models/src/models/page-key';

class UsersAdminMenuIcon implements MenuItemIcon {
  key: PageKey = PageKey.ADMINS;
  icon: ReactNode = (<UserGroup />);
}

export const usersAdminMenuIcon = new UsersAdminMenuIcon();
