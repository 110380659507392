import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ThemeModel } from 'front-components/src/themes/theme-model';
import { getThemeMode } from 'front-components/src/tools/theme-extensions';

export const useStyles = makeStyles(
  (theme: Theme) => {
    const mode: ThemeModel = getThemeMode(theme);
    return createStyles({
      menuContainer: {
        height: '100%',
        width: '100%',
        borderRight: '1px solid',
        borderRightColor: mode.general.outline,
        backgroundColor: mode.general.sideNavigation,
      },
      footerContainer: {
        borderRight: '1px solid',
        borderRightColor: mode.general.outline,
        paddingLeft: '20px',
        backgroundColor: mode.general.sideNavigation,
      },
      shrinkButtonContainer: {
        paddingTop: '12px',
        paddingBottom: '12px',
      },
      versionLabelContainer: {
        paddingTop: '16px',
        paddingBottom: '18px',
      },
      versionLabel: {
        fontFamily: 'Inter',
        fontSize: '10px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.4,
        letterSpacing: 'normal',
        color: mode.text.text01,
      },
      adminMenuWrapper: {
        height: '100%',
      },
      appLink: {
        textDecoration: 'none',
      },
      backButtonContainer: {
        height: '62px',
        borderBottom: `1px solid ${mode.general.outline}`,
        display: 'flex',
        '& button': {
          width: '100%',
          display: 'block',
          textTransform: 'none',
          borderRadius: '0px',
        },
      },
      backButtonColors: {
        color: `${theme.palette.primary.main} !important`,
        '& path': {
          fill: `${theme.palette.primary.main} !important`,
        },
      },
    });
  },
  { name: 'AdminMenu' }
);
