import { MenuItemIcon } from './menuitem-icon';
import {
  resellerMenuIcon,
  customerMenuIcon,
  homeMenuIcon,
  usersMenuIcon,
  settingsMenuIcon,
  generalSettingsMenuIcon,
  apperanceSettingsMenuIcon,
  emailsSettingsMenuIcon,
  smsSettingsMenuIcon,
  dashboardMenuIcon,
  mySettingsMenuIcon,
  myGeneralSettingsMenuIcon,
  myOrganizationMenuIcon,
  settingsOrganizationMenuIcon,
  usersStandardMenuIcon,
  usersAdminMenuIcon,
  emailSettingsMenuIcon,
} from './menu-icons';

export const iconsSet: MenuItemIcon[] = [
  dashboardMenuIcon,
  resellerMenuIcon,
  customerMenuIcon,
  homeMenuIcon,
  usersMenuIcon,
  settingsMenuIcon,
  generalSettingsMenuIcon,
  apperanceSettingsMenuIcon,
  emailsSettingsMenuIcon,
  smsSettingsMenuIcon,
  mySettingsMenuIcon,
  myGeneralSettingsMenuIcon,
  myOrganizationMenuIcon,
  settingsOrganizationMenuIcon,
  usersStandardMenuIcon,
  usersAdminMenuIcon,
  emailSettingsMenuIcon,
];
