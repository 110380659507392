import React from 'react';
import { AntSwitch, Header, MenuItem } from 'front-components/src/components';
import {
  sessionSelector,
  updateSessionEndDate,
} from '../../state/reducers/session';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeMode } from 'front-components/src/constants';
import {
  DarkModeIcon,
  GoToArrowIcon,
  LanguageIcon,
  SettingsIcon,
  LogOutIcon,
} from 'front-components/src/components/icons';
import { userService } from '@myneva-portals/services/src/services';
import { uiSelector, updateThemeMode } from '../../state/reducers/ui';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import PageKey from '@myneva-portals/models/src/models/page-key';
import { useStyles } from './portal-header.styles';
import {
  navigationSelector,
  updateLastSelectedMenuItem,
} from '../../state/reducers/navigation';
import { MenuType } from '@myneva-portals/models/src/models/navigation/menu-type';

export const PortalHeader: React.FC = () => {
  const { sessionEndDate, userName, userType } = useSelector(sessionSelector);
  const { themeMode } = useSelector(uiSelector);
  const { selectedMenuItem, menuType } = useSelector(navigationSelector);

  const dispatch = useDispatch();

  const toggleThemeMode = () => {
    themeMode === ThemeMode.LIGHT
      ? dispatch(updateThemeMode(ThemeMode.DARK))
      : dispatch(updateThemeMode(ThemeMode.LIGHT));
  };

  const classes = useStyles();

  const accountMenu = (
    <div>
      <MenuItem
        name={'Dark Mode'}
        icon={<DarkModeIcon className={classes.menuIcon} />}
        onClick={() => toggleThemeMode()}
        additionalComponent={
          <AntSwitch
            className={classes.menuAdditionalIcon}
            checked={themeMode === ThemeMode.DARK}
          />
        }
        cyName="top-right-bar-dark-mode-button"
      />
      <MenuItem
        name={'Change Legal Entity'}
        icon={<LanguageIcon className={classes.menuIcon} />}
        additionalComponent={
          <GoToArrowIcon className={classes.menuAdditionalIcon} />
        }
        cyName="top-right-bar-change-legal-entity-button"
      />
      <MenuItem
        name={'Settings'}
        icon={<SettingsIcon className={classes.menuIcon} />}
        onClick={() => {
          if (menuType === MenuType.MAIN_MENU) {
            dispatch(updateLastSelectedMenuItem(selectedMenuItem));
          }

          navigate(PageKey.USER_SETTINGS_OVERVIEW);
        }}
        cyName="top-right-bar-settings-button"
      />
      <MenuItem
        name={'Log Out'}
        icon={<LogOutIcon className={classes.menuIcon} />}
        onClick={() => {
          userService.logout();
        }}
        cyName="top-right-bar-log-out-button"
      />
    </div>
  );

  const { t } = useTranslation();
  return (
    <Header
      sessionEndDateInMs={sessionEndDate}
      onSessionTimerClick={() => {
        dispatch(
          updateSessionEndDate(
            new Date().getTime() + userService.sessonTimeInMs
          )
        );
      }}
      name={userName}
      searchPlaceholder={t('SEARCH')}
      watchTitle={t('TIME_TO_LOGOUT')}
      type={userType}
      accountMenu={accountMenu}
      logoutWarningTimeInMs={userService.sessonTimeInMs * 0.3}
      onSessionEndCallback={() => {
        userService.logout();
      }}
      onLogoClick={() => {
        navigate(PageKey.DASHBOARD);
      }}
    />
  );
};
