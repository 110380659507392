import React from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PageKey from '@myneva-portals/models/src/models/page-key';
import { iconsSet as menuItemIcons } from './icons-set';
import { MenuItemIcon } from './menuitem-icon';

export class IconFactory {
  getIcon(key: PageKey): React.ReactNode {
    const menuItemIcon: MenuItemIcon | undefined = menuItemIcons.find(
      (icon: MenuItemIcon) => icon.key === key
    );
    return menuItemIcon ? menuItemIcon.icon : <CloudUploadIcon />;
  }
}
