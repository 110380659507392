import React, { ReactNode } from 'react';
import { MenuItemIcon } from '../menuitem-icon';
import PageKey from '@myneva-portals/models/src/models/page-key';
import { MobileSignal } from 'front-components/src/components/icons';

class SmsSettingsMenuIcon implements MenuItemIcon {
  key: PageKey = PageKey.SMS_SETTINGS;
  icon: ReactNode = (<MobileSignal />);
}

export const smsSettingsMenuIcon = new SmsSettingsMenuIcon();
