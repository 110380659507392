import { ReactNode } from 'react';
import PageKey from '@myneva-portals/models/src/models/page-key';
import { IconFactory } from './icons/icon-factory';

class IconUtils {
  getIconForMenuItem(key: PageKey): ReactNode {
    const iconFactory: IconFactory = new IconFactory();
    return iconFactory.getIcon(key);
  }
}

export const iconUtils = new IconUtils();
