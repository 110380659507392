import React, { ReactNode } from 'react';
import { MenuItemIcon } from '../menuitem-icon';
import PageKey from '@myneva-portals/models/src/models/page-key';
import { PoolHorizontal } from 'front-components/src/components/icons';

class MyOrganizationIcon implements MenuItemIcon {
  key: PageKey = PageKey.MY_ORGANIZATION;
  icon: ReactNode = (<PoolHorizontal />);
}

export const myOrganizationMenuIcon = new MyOrganizationIcon();
