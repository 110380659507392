import React, { ReactNode } from 'react';
import { MenuItemIcon } from '../menuitem-icon';
import PageKey from '@myneva-portals/models/src/models/page-key';
import { Gear } from 'front-components/src/components/icons';

class SettingsMenuIcon implements MenuItemIcon {
  key: PageKey = PageKey.SETTINGS;
  icon: ReactNode = (<Gear />);
}

export const settingsMenuIcon = new SettingsMenuIcon();
