import React, { ReactNode } from 'react';
import { MenuItemIcon } from '../menuitem-icon';
import PageKey from '@myneva-portals/models/src/models/page-key';
import { PaintRoller } from 'front-components/src/components/icons';

class ApperanceSettingsMenuIcon implements MenuItemIcon {
  key: PageKey = PageKey.APPEARANCE_SETTINGS;
  icon: ReactNode = (<PaintRoller />);
}

export const apperanceSettingsMenuIcon = new ApperanceSettingsMenuIcon();
