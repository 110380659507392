import React, { ReactNode } from 'react';

import { UserGroupCrown } from 'front-components/src/components/icons';
import { MenuItemIcon } from '../menuitem-icon';
import PageKey from '@myneva-portals/models/src/models/page-key';

class UsersStandardMenuIcon implements MenuItemIcon {
  key: PageKey = PageKey.STANDARD_USERS;
  icon: ReactNode = (<UserGroupCrown />);
}

export const usersStandardMenuIcon = new UsersStandardMenuIcon();
