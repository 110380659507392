import React, { ReactNode } from 'react';
import { MenuItemIcon } from '../menuitem-icon';
import PageKey from '@myneva-portals/models/src/models/page-key';
import { PaintRoller } from 'front-components/src/components/icons';
import { Envelope } from 'front-components/src/components/icons';

class EmailsSettingsMenuIcon implements MenuItemIcon {
  key: PageKey = PageKey.EMAILS_SETTINGS;
  icon: ReactNode = (<Envelope />);
}

export const emailsSettingsMenuIcon = new EmailsSettingsMenuIcon();
