import React, { ReactNode } from 'react';
import { MenuItemIcon } from '../menuitem-icon';
import PageKey from '@myneva-portals/models/src/models/page-key';
import { Grid2 } from 'front-components/src/components/icons';

class DashboardMenuIcon implements MenuItemIcon {
  key: PageKey = PageKey.DASHBOARD;
  icon: ReactNode = (<Grid2 />);
}

export const dashboardMenuIcon = new DashboardMenuIcon();
